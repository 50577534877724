@media screen and (max-width: 450px) {
  html {
    font-size: 12px;
  }
}

// @media screen and (max-width: 600px) {
//   html {
//     font-size: 14px;
//   }
// }

html , *{
  font-family: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  // background: #eb4c42;
  // background: #D7C1B3;
  background: #fbfaf9;
  // background: #e0cfc4;
  margin: 0;
}

.header {
  margin: 50px 60px 25px;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin: 30px 10px 10px;
  }
}

.Date-header {
  h1 {
    font-weight: 600;
    margin: 0;
    font-size: 36px;
    line-height: normal;
  }
  background-color: #eb4c42;
  color: black;
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 100px;
  padding-bottom: 5px;;
  width:fit-content;
}

.outdoor-details {
  h1 {
    font-size: 28px;
  }
  justify-content: center;
  // background: #eba142;
  // border-top-left-radius: 50px;
  // padding: 25px 25px;
  color: #ED6A5A;
}

.outdoor-temp {
  // margin-bottom: 40px;
  // align-items: center;
  // background: #eb4c42;
  // border-bottom-left-radius: 100px;
  // border-bottom-right-radius: 100px;
  // justify-content: center;
  // text-align: center;
  // padding: 50px 80px;

  h1 {
    grid-row: 1 / 3;
    grid-column: 2;
    font-size: 6.5rem;
    font-weight: 600;
    margin: 0 8px;

    span {
      font-size: 20px;
    }
  }
  h2 {
    // font-weight: 400;
    // margin-top: 0;
    // background: white;
    // color: #EB4C58;
    // border-radius: 30px;
    // padding: 4px 25px;
    font-size: 1.75rem;
  }

  span {
    font-weight: 600;
    font-size: 2.0rem;
  }
}

#feels-temp {
  // margin-top: 0;
  grid-column: 2;
  grid-row: 3;
  width: fit-content;
  // background: white;
  // border-radius: 30px;
  // padding: 4px 25px;
  // font-size: 2rem;
  margin: 0 8px;
  justify-self: center;
  grid-column: 2 / 4;

  @media screen and (max-width: 1280px) {
    grid-column: 2 / 4;
  }
}

.outdoor-temp-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#forecast-high {
  grid-column: 3;
  align-self: flex-end;
  margin-bottom: 5px;
}

#forecast-low {
  grid-column: 3;
  grid-row: 2;
  margin-top: 5px;
}

.minor-label {
  font-size: 1.4rem;
  font-weight: 400;
}

.App-link {
  color: #61dafb;
}

.rain-details {
  h1 {
    font-size: 28px;
  }
  // justify-content: center;
  padding: 25px 25px;
  color: white;
  background: #428ceb;
  border-radius: 25px;
  margin: 15px;
  flex-basis: 28%;

  h2 {
    margin-top: 0;
  }
}

.info-item {
  // display: flex;
  // justify-content: flex-end;
  // flex-direction: column;
}

#item-box {
  // border-radius: 10px;
  // box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
  // background: #fef4f3;
  // margin: 60px 20px;
  // text-align: center;
  padding: 20px 15px;
  justify-content: space-evenly;

  // @media screen and (min-width: 1400px) {
  //   flex-basis: 66.66667%;
  //   max-width: 66.66667%;
  //   margin-left: 16.66667%;
  // }
}

.glance-item {
  // border: 3px solid #eb4c42;
  // border-radius: 10px;
  // box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
  // background: #fef4f3;
  // color: #eb4c42;
  margin: 0 0 40px 0;
  // text-align: center;
  // padding: 20px 15px;

  h1 {
    font-size: 2.2rem;
    font-weight: 600;
    margin: 0;
    // margin-bottom: 30px;
    // text-align: center;
  }
  h2 {
    font-size: 36px;
    margin-bottom: 35px;
    margin-top: 0;
  }
  h4 {
    background: #446767;
    color: #fbfaf9;
    // border-radius: 20px;
    padding: 5px 15px;
    margin: 8px 0;
    font-size: 1.3rem;
    font-weight: 600;
    width: fit-content;
    // border: 2px solid #eb4c42;
  }

  // h4 {
  //   margin: 0;
  //   font-size: 1.4rem;
  // }

  span {
    font-size: 1.3rem;
    font-weight: 400;
  }

  // @media screen and (max-width: 500px) {
  //   flex-basis: 80%;
  //   max-width: 80%;

  //   h1 {
  //     margin-bottom: 15px;
  //   }
  // }

  // @media screen and (min-width: 960px) {
  //   flex-basis: 33.33%;
  //   max-width: 33.33%;
  // }

  // @media screen and (min-width: 1100px) {
  //   flex-basis: 25%;
  //   max-width: 25%;
  // }

  // @media screen and (min-width: 1400px) {
  //   flex-basis: 21%;
  //   max-width: 21%;
  // }
}

.more-details {
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

.outdoor-temp-grid {
  display: grid;
  grid-template-columns: 1fr max-content max-content 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  margin-bottom: 15px;
}

.chart-item {  
  // box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
  color: #eb4c42;
  margin-bottom: 20px;
  text-align: center;
  // padding: 20px 15px;
  // position: relative;

  h4 {
    background: #eb4c42;
    color: white;
    border-radius: 30px;
    padding: 5px 15px;
    margin: 8px;
    font-size: 1.2rem;
    // border: 2px solid #eb4c42;
  }

  // @media screen and (min-width: 1280px) {
  //   flex-basis: 48%;
  //   max-width: 48%;
  // }
  // @media screen and (min-width: 1400px) {
  //   flex-basis: 45%;
  //   max-width: 45%;
  // }
  // @media screen and (max-width: 500px) {
  //   flex-basis: 75%;
  //   max-width: 75%;
  // }
  // @media screen and (min-width: 960px) {
  //   flex-basis: 33.33%;
  //   max-width: 33.33%;
  // }
}

.detail-wrapper {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
}

.app-grid {
  display: grid;
  grid-template-rows: auto 1fr;
  color: #446767;
}

.almanac-container {
  // background-color: #d76355;
  // margin: 50px 100px;
  color: #446767;

  h1 {
    font-size: 2.0rem;
    font-weight: 600;
    padding: 15px;
  }

  h3 {
    // margin: 8px 0;
    font-size: 2.2em;
    font-weight: 600;
  }

  h4 {
    background-color: #446767;
    color: #fbfaf9;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 5px 15px;
    width: fit-content;
  }
}

.section-title {
  border-bottom: #446767 solid 1px;
  margin-right: 0;
}

.chart-container {
  position: relative; 
  width:80vw;
  height: 35vh;

  // @media screen and (max-width: 1366px) {
  //   position: relative; 
  //   width:80vw;
  //   height: 35vh;
  // }

  // @media screen and (max-width: 1024px) {
  //   position: relative; 
  //   width:43vw;
  //   height: 28vh;
  // }

  @media screen and (max-width: 500px) {
    position: relative; 
    width:96vw;
    height: 31vh;
  }
}